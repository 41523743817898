<template>
  <div>
    <NavBar class="nav-bar">
      <div slot="title">商品收藏</div>
    </NavBar>
    <!-- 收藏商品详情 -->
    <div class="back-color">
      <div class="empty-box" v-if="list.length <= 0">
        <!-- 无商品 -->
        <div class="empty-layout">
          <div class="img-box">
            <img
              src="~@/assets/common/collect-blank-images@2x.png"
              class="empty-img"
            />
          </div>
          <div class="empty-text">你还没有任何收藏~</div>
        </div>
      </div>
      <!-- 有商品 -->
      <div v-else>
        <div class="list-layout" v-for="(goods, index) in list" :key="index">
          <div class="left-layout" @click="goToGoodsInfo(goods.goods_id)">
            <div class="goods-img-box">
              <img :src="goods.cover_img" class="goods-img" />
            </div>
            <div class="goods-info">
              <span class="goods-title">{{ goods.good_name }}</span>
              <span class="goods-price">￥{{ goods.price }}</span>
            </div>
          </div>
          <div class="operation-layout">
            <van-icon
              name="delete-o"
              size="20"
              @click="setGoodsCollect(goods.goods_id, index)"
              @click.stop
            />
            <div class="cart-button" @click="goTobuy(goods.goods_id)">
              <img
                src="~@/assets/common/shopping-cart-icon@2x.png"
                class="button-img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="recommend-layout">
      <div class="recommend-title">-为你推荐-</div>
      <GoodsShowLabel :tagId="0" :sendType="3"></GoodsShowLabel>
    </div>
    <Sku
      :goodsClass="goodsClass"
      :showPopup="isSku"
      @closeSku="closeSku"
      v-if="isSku"
    ></Sku>
    <PromptPopup :showPopup="showPopup" @popupFn="popupFn"></PromptPopup>
  </div>
</template>

<script>
import Sku from "@/pages/new-goods/components/sku/sku.vue";
import PromptPopup from "@/components/prompt-popup/prompt-popup";
import NavBar from "@/layout/nav-bar/nav-bar";
import GoodsShowLabel from "@/components/goods-show/goods-show-label.vue";
import { getCollectList, setGoodsCollect } from "@/api/goods";
import { Toast } from "vant";
export default {
  name: "my-collection",
  components: {
    GoodsShowLabel,
    NavBar,
    PromptPopup,
    Sku,
  },
  data() {
    return {
      list: [],
      page: 1,
      total: 100,
      isSku: false,
      showPopup: false,
      indexNum: "",
      goodsClass: {
        goodsId: "",
        statusCode: 1,
        goodsType: 1,
      },
    };
  },
  mounted() {
    this.getCollectList();
  },
  methods: {
    goTobuy(goodsId) {
      // 去购买
      console.log(this.goodsClass.goodsId);
      this.goodsClass.goodsId = goodsId;
      console.log(this.goodsClass.goodsId);
      this.isSku = true;
    },
    closeSku(bool) {
      // sku回调
      this.isSku = bool;
      // this.getCollectList();
    },
    goToGoodsInfo(goodsId) {
      this.$router.push({
        name: "goods",
        query: { goodsId: goodsId, type: 1 },
      });
    },
    async getCollectList() {
      if (this.list.length >= this.total) {
        return false;
      }
      const ret = await getCollectList({ page: this.page });
      if (ret.code * 1 == 1) {
        this.list = this.list.concat(ret.data.items);
        this.total = ret.data.total;
        ++this.page;
      }
    },
    setGoodsCollect(goods_id, index) {
      this.goodsClass.goodsId = goods_id;
      this.indexNum = index;
      this.showPopup = true;
    },
    async popupFn(bool) {
      if (bool) {
        const ret = await setGoodsCollect({
          goods_id: this.goodsClass.goodsId,
          is_collect: 0,
        });
        if (ret.code * 1 === 1) {
          Toast.success("取消收藏");
          this.list.splice(this.indexNum, 1);
          --this.total;
        }
      }
      this.showPopup = false;
    },
  },
};
</script>

<style lang="less" scoped>
.nav-bar {
  background-color: #fff;
  border-bottom: 1px solid #ededed;
}
.back-color {
  padding-top: 58px;
  background-color: #fff;
}
.empty-box {
  width: 100vw;
  padding: 44px 0 44px 0;
  .empty-layout {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    .img-box {
      width: 220px;
      .empty-img {
        width: 100%;
      }
    }
  }
  .empty-text {
    margin-top: 27px;
    font-size: 18px;
    color: #aaa;
  }
}
// 有商品的布局
.list-layout {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  border-bottom: 1px solid #ededed;
}
.left-layout {
  display: flex;
  width: 80%;
  .goods-img-box {
    width: 90px;
    .goods-img {
      width: 90px;
      height: 90px;
      display: block;
    }
  }
  .goods-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4px 0 10px 12px;
    .goods-title {
      font-size: 18px;
      font-weight: 500;
      display: -webkit-box; /**对象作为伸缩盒子模型展示**/
      -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
      -webkit-line-clamp: 2; /**显示的行数**/
      overflow: hidden; /**隐藏超出的内容**/
    }
    .goods-price {
      font-size: 18px;
      font-weight: 600;
      color: #ed301d;
    }
  }
}
.operation-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 0 0;
  .cart-button {
    width: 40px;
    height: 40px;
    .button-img {
      width: 100%;
    }
  }
}
// 为你推荐
.recommend-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
}
</style>
