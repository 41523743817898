<template>
  <van-popup
    v-model="showPopup"
    :close-on-click-overlay="false"
    @click-overlay="cancelBtn"
  >
    <span class="title">{{ msg }}</span>
    <slot name="title"></slot>
    <div class="is-button">
      <div class="button">
        <van-button
          type="danger"
          plain
          round
          block
          @click="cancelBtn"
          class="textSize"
        >
          取消
        </van-button>
      </div>
      <div class="button">
        <van-button
          type="danger"
          round
          block
          @click="confirmBtn"
          class="textSize"
        >
          确认
        </van-button>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: "prompt-popup",
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
    msg: {
      type: String,
      default: "是否确认删除？",
    },
  },
  data() {
    return {};
  },
  methods: {
    cancelBtn() {
      this.$emit("popupFn", false);
    },
    confirmBtn() {
      this.$emit("popupFn", true);
    },
  },
};
</script>

<style lang="less" scoped>
// 弹出层
.van-popup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px 14px;
  width: 320px;
  height: 190px;
  border-radius: 16px;
  box-sizing: border-box;

  .is-button {
    display: flex;
    justify-content: space-around;
    width: 100%;

    .button {
      width: 116px;
    }
  }
}
.title {
  font-size: 20px;
}
.van-button {
  line-height: 0;
}
.textSize {
  font-size: 18px;
}
</style>


